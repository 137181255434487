@import url('https://fonts.googleapis.com/css?family=Ubuntu');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

$primary: #f79421;
$link: #f79421;

// $size-1: 3rem;
$size-2: 2.4rem; //title
$size-3: 1.9rem; //subtitle
// $size-4: 1.5rem;
// $size-5: 1.25rem;
// $size-6: 1rem;
// $size-7: 0.75rem; //content is-small

$body-size: 1.1rem;


$body-family: 'Ubuntu', 'Nunito Sans', Calibri, Verdana, Segoe, Arial, sans-serif;


// Import Main CSS file from theme
@import "main";


.content {
	text-align: justify
}

// Keep certain content items around the site centered rather than justified.
// Generally where there is only minimal text
.content-center {
	text-align: center
}

// Make logo in navbar slightly bigger (in header.html)
// This effectively sets the height of the top menubar
// (See next item)
.navbar-item-logo img {
    max-height: 2.5rem;
}

// Since the menubar is fixed, we need to add a margin when we jump/internal link to the 
// h2-h4 headings and for divs, for example when using a table of contents. Otherwise the 
// jumped to heading will be covered by the menu bar.
h2 {
	scroll-margin-top: 2em;
}
h3 {
	scroll-margin-top: 2.5em;
}
h4 {
	scroll-margin-top: 3em;
	margin-top: 1em; // For some reason h4 was squashing up against previous content so this gives it some space
}
div {
	scroll-margin-top: 3.5em;
}

// Without this, the modal will go wider than a mobile screen.
// Plus at the same time we override the previously transparent
// background colour
.modal-content-narrower {
	max-width: 500px;
	background-color: #FFFFFF;
}

// Makes sure the image on the post card on the list of 
// blog posts is not too big and is centred
.card-image {
	max-width: 500px;
	margin-left:auto;
	margin-right:auto;
}

.blog-image{
	max-height: 300px;
	display:block;
	margin-left:auto;
	margin-right:auto;
}

.blog-image-tall{
	max-height: 600px;
}

.content-blog
{
	max-width: 750px;
	margin-left:auto;
	margin-right:auto;
}

.card-image-inner{
	max-height: 200px;
	display:block;
	margin-left:auto;
	margin-right:auto;
	margin-bottom: 10px;
}

// -------- Font awesome characters

$thumb: "\f00c";
$x: "\f00d";
$dollar:"\f653";
$crown:"\f521";
$beach:"\f5ca";
$satellite:"\f7c0";
$fingerprint:"\f577";
$hand-heart:"\f4be";
$graduation:"\f19d";
$info:"\f05a";
$action:"\f124";
$system:"\f108";


// -------- Font awesome styles
@counter-style benefits {
    system: cyclic;
    symbols: $dollar $crown $beach $satellite $fingerprint $hand-heart $graduation; 
    suffix: " ";
}

.list-awesome {
	li {
		padding-inline-start: 2ch;

		&::marker {
			-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;
			display:var(--fa-display,inline-block);
			font-style:normal;
			font-variant:normal;
			line-height:1;
			text-rendering:auto;
			font-family:"Font Awesome 5 Free";
			font-weight:900;
			font-size: x-large;
		}
	}

	li::marker {
		color: $primary;
	}
}

.list-awesome-good ul { 
	@extend .list-awesome;

	list-style: $thumb;
	li::marker {
		color: lightgreen;
	}
}

.list-awesome-bad ul { 
	@extend .list-awesome;

	list-style: $x;
	li::marker {
		color:red;
	}
}

.list-awesome-benefits ul { 
	@extend .list-awesome;

	list-style: benefits;
	li::marker {
		color: $primary;
	}
}

.list-awesome-info ul { 
	@extend .list-awesome;

	list-style: $info;
	li::marker {
		color: darkblue;
		font-size: medium;
	}
}

.list-awesome-action ul { 
	@extend .list-awesome;

	list-style: $action;
	li::marker {
		color: darkgreen;
		font-size: medium;
	}
	
}

.list-awesome-system ul { 
	@extend .list-awesome;

	list-style: $system;
	li::marker {
		color: #f79421;
		font-size: medium;
	}
}

[hidden] { 
	display:none; 
}
